import {
  SHIPMENT_LIST_TRADE_DIRECTION,
  SHIPMENT_LIST_FILTERS,
  SHIPMENT_LIST_ACTIVE_VIEW,
  SHIPMENT_LIST_SORT,
} from '../../utils/constants/shipments';
import { getLocalStorageObject } from '../../utils/utils';
import { cascadeForViewFilter } from './cascades';
import * as types from './types';

// Load the values from the local storage so we can persist
const savedActiveTradeDirection = localStorage.getItem(SHIPMENT_LIST_TRADE_DIRECTION);
const savedFilters = getLocalStorageObject(SHIPMENT_LIST_FILTERS, {});
const savedViewFilters = getLocalStorageObject(SHIPMENT_LIST_ACTIVE_VIEW, {});
const savedSortOptions = getLocalStorageObject(SHIPMENT_LIST_SORT, {});

// Initial state can be set here, although can also be fed in through createStore
const initial = {
  activeTradeDirection: savedActiveTradeDirection || 'import',
  sort: savedSortOptions,
  filters: savedFilters,
  searchString: '',
  view: savedViewFilters,
  sidebarWasCollapsed: null,
};

const shipments = (state = initial, action) => {
  switch (action.type) {
    case types.TRADE_DIRECTION_EDIT: {
      const { activeTradeDirection } = action.payload;

      // Save to the local storage
      localStorage.setItem(SHIPMENT_LIST_TRADE_DIRECTION, activeTradeDirection);

      return {
        ...state,
        activeTradeDirection,
      };
    }

    case types.SHIPMENT_SORT_EDIT: {
      const { customerTeamId, tradeDirection, option } = action.payload;

      const newSort = {
        ...state.sort,
        [customerTeamId]: {
          ...state.sort?.[customerTeamId],
          [tradeDirection]: option,
        },
      };

      localStorage.setItem(SHIPMENT_LIST_SORT, JSON.stringify(newSort));

      return {
        ...state,
        sort: newSort,
      };
    }

    case types.SHIPMENT_SEARCH_EDIT: {
      const { searchString } = action.payload;
      return {
        ...state,
        searchString,
      };
    }

    case types.SHIPMENT_VIEW_EDIT: {
      const { customerTeamId, tradeDirection, view } = action.payload;

      const newView = {
        ...state.view,
        [customerTeamId]: {
          ...state.view?.[customerTeamId],
          [tradeDirection]: view,
        },
      };

      localStorage.setItem(SHIPMENT_LIST_ACTIVE_VIEW, JSON.stringify(newView));

      const newState = {
        ...state,
        view: newView,
      };

      return cascadeForViewFilter(newState, customerTeamId);
    }

    case types.SHIPMENT_FILTER_EDIT: {
      const {
        customerTeamId, tradeDirection, filter, value,
      } = action.payload;

      const filters = {
        ...state.filters,
        [customerTeamId]: {
          ...state.filters?.[customerTeamId],
          [tradeDirection]: {
            ...state.filters?.[customerTeamId]?.[tradeDirection],
            [filter]: value,
          },
        },
      };

      localStorage.setItem(SHIPMENT_LIST_FILTERS, JSON.stringify(filters));

      return {
        ...state,
        filters,
      };
    }

    case types.SHIPMENT_SIDEBAR_PREVIOUS_EDIT: {
      const { sidebarWasCollapsed } = action.payload;

      return {
        ...state,
        sidebarWasCollapsed,
      };
    }

    default:
      return state;
  }
};

export default shipments;
