import { STORAGE_ALLOW_UI_EVENTS } from '@/shared/utils/constants/auth';

import { UIEVENTS_PUSH } from '../uiEvents/types';

/**
 * Middleware to stop any UI events being pushed for Internal Mode.
 */
const allowUIEvents = () => (next) => (action) => {
  if (action?.type === UIEVENTS_PUSH) {
    // Prevent UI events from being pushed for Internal Mode
    const isUiEventsAllowed = !!localStorage.getItem(STORAGE_ALLOW_UI_EVENTS);
    if (isUiEventsAllowed) {
      return null;
    }
  }

  return next(action);
};

export default allowUIEvents;
