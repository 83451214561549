import { ApolloLink, ApolloProvider } from '@apollo/client';
import { persistCache } from 'apollo3-cache-persist';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { useAuth } from '@/shared/utils/hooks/useAuth';

import { apolloClient, cache } from './ApolloClient';
import {
  createErrorLink,
  createHeadersAuthLink,
  createMainLink,
} from './apolloLinks';
import indexedDBWrapper from './indexedDBWrapper';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const firefoxPrivateCheck = () => {
  const isFirefox = 'MozAppearance' in document.documentElement.style;
  if (!isFirefox) {
    return Promise.resolve(false);
  }
  // If no tab is open in FF, indexedDB is not available
  // This is theoretically impossible for our case,
  // But I'd like to leave this check in anyway.
  if (indexedDB === null) {
    return Promise.resolve(true);
  }
  return new Promise((resolve) => {
    const db = indexedDB.open('shpFFprivatCheck');
    db.onsuccess = () => {
      db.result.close();
      indexedDB.deleteDatabase('shpFFprivatCheck');
      resolve(false);
    };
    db.onerror = () => {
      indexedDB.deleteDatabase('shpFFprivatCheck');
      resolve(true);
    };
  });
};

const ApolloWrapper = ({ children }) => {
  const {
    getToken, logout, isStaffUser, user,
  } = useAuth();
  const [client, setClient] = useState();

  /**
   * If an auth token is present at the time of mount, this creates a client
   * whose subscription link is able to connect to AppSync.
   * If an auth token is **not** present on mount, because user is not yet logged in,
   * this creates an "unauthorized" client able to login, but unable to connect to AppSync
   * Once a token is available after login, this creates a new client that **is** able to
   * connect to AppSync.
   */
  useEffect(() => {
    (async () => {
      const token = await getToken();

      // Update the Apollo Client
      apolloClient.setLink(ApolloLink.from([
        createHeadersAuthLink(token, isStaffUser),
        createErrorLink(logout),
        createMainLink(token),
      ]));

      // Don't enable persist cache for staff, I'm not sure how we
      // can clear storage if the staff user logs out on BO...
      if (!token || isStaffUser()) {
        setClient(apolloClient);
      } else {
        // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.onResetStore
        // When we reset the store, make sure to delete all cached data from apollo-cache-persist
        apolloClient.onResetStore(() => {
          apolloClient.writeData({ data: {} });
        });
        // Our implementation of indexedDB made it necessary to check for Firefox + Private Browsing
        // This is necessary since FF Private does not allow indexedDB to be used.
        // In this case, we fall back on the localStorage.
        // This code has been adapted from: https://gist.github.com/jherax/a81c8c132d09cc354a0e2cb911841ff1
        firefoxPrivateCheck().then((isFirefoxPrivate) => (
          persistCache({
            cache,
            storage: isFirefoxPrivate ? window.localStorage : indexedDBWrapper,
            // Un-limit the max size, default ~ 1MB
            maxSize: false,
          })
        )).then(() => {
          setClient(apolloClient);
        }).catch((error) => {
          // What kind of error handling should we have here?
          // eslint-disable-next-line no-console
          console.error('Error restoring Apollo cache', error);
        });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // used to trigger updates if auth changes
    user,
  ]);

  if (client === undefined) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

ApolloWrapper.propTypes = propTypes;

export default ApolloWrapper;
