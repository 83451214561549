import { DRAFT_CHAT_MESSAGES } from './chat';
import {
  SHIPMENT_LIST_TRADE_DIRECTION,
  SHIPMENT_LIST_FILTERS,
  SHIPMENT_LIST_ACTIVE_VIEW,
  SHIPMENT_LIST_ACTIVE_FILTERS,
  SHIPMENT_LIST_SORT,
} from './shipments';
import { SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY } from './sidebar';
import { STORAGE_DASHBOARD_ASSIGNEE } from '../../../AppMain/utils/constants/dashboard';
import { ACTIVE_TAB_LOCAL_STORAGE_KEY } from '../../../utils/constants/quote';
import {
  STORAGE_TRADE_DIRECTION,
  STORAGE_TRANSPORTATION_MODE,
  STORAGE_INCOTERMS,
  STORAGE_ROUTE,
  STORAGE_CARGO_TYPE,
  STORAGE_ASSIGNEE,
  WALK_THROUGH_KEY,
} from '../../../utils/constants/simulations';

const NON_STAFF_X_AUTH_ROLE = 'Authentication';

const USER_TYPE = {
  staff: 'Staff',
  customerUser: 'CustomerUser',
  cpuWarehouse: 'CpuWarehouse',
  forwarderUser: 'ForwarderUser',
};

export const STORAGE_ALLOW_UI_EVENTS = 'allowUIEvents';

const LOCAL_STORAGE_REMOVABLE_KEYS = [
  ACTIVE_TAB_LOCAL_STORAGE_KEY,
  DRAFT_CHAT_MESSAGES,
  SHIPMENT_LIST_ACTIVE_FILTERS,
  SHIPMENT_LIST_TRADE_DIRECTION,
  SHIPMENT_LIST_FILTERS,
  SHIPMENT_LIST_ACTIVE_VIEW,
  SHIPMENT_LIST_TRADE_DIRECTION,
  SHIPMENT_LIST_SORT,
  SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY,
  STORAGE_ASSIGNEE,
  STORAGE_DASHBOARD_ASSIGNEE,
  STORAGE_CARGO_TYPE,
  STORAGE_INCOTERMS,
  STORAGE_ROUTE,
  STORAGE_TRADE_DIRECTION,
  STORAGE_TRANSPORTATION_MODE,
  WALK_THROUGH_KEY,
  STORAGE_ALLOW_UI_EVENTS,
];

export { LOCAL_STORAGE_REMOVABLE_KEYS, NON_STAFF_X_AUTH_ROLE, USER_TYPE };
