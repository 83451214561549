/* eslint-disable camelcase */

import { User } from '@auth0/auth0-react';

export enum CompanyType {
  Forwarder = 'forwarder',
  Subsidiary = 'local_subsidiary',
}

// TODO: will be replaced with CompanyType in the future (see above)
export enum LoginUserRole {
  Staff = 'Staff',
  Customer = 'CustomerUser',
  Warehouse = 'CpuWarehouse',
  Forwarder = 'ForwarderUser',
}

export type CustomUser = User & {
  lang: 'jp' | 'en';
  login_user_role: LoginUserRole;
  company_type?: CompanyType;
  company_name: string;
  company_id: string;
}
