import { getLocalStorageObject } from '../../shared/utils/utils';
import * as types from './types';

const LOCAL_STORAGE_KEY = 'invoice-list-filters';

const savedFilters = getLocalStorageObject(LOCAL_STORAGE_KEY, { statusFilter: 'all' });

const initial = {
  searchString: '',
  ...savedFilters,
};

const invoices = (state = initial, action) => {
  switch (action.type) {
    case types.INVOICE_STATUS_FILTER_EDIT: {
      const { status: statusFilter } = action.payload;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ statusFilter }));
      return {
        ...state,
        statusFilter,
      };
    }
    case types.INVOICE_SEARCH_EDIT: {
      const { searchString } = action.payload;
      return {
        ...state,
        searchString,
      };
    }
    case types.ANYCARGO_MONTH_EDIT: {
      const { monthString } = action.payload;
      return {
        ...state,
        monthString,
      };
    }
    case types.ANYCARGO_YEAR_EDIT: {
      const { yearString } = action.payload;
      return {
        ...state,
        yearString,
      };
    }
    default:
      return state;
  }
};

export default invoices;
