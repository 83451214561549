import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

import { Auth } from '@/shared/utils/auth/auth';

import { CustomUser } from '../auth/typings';

export const useAuth = () => {
  const auth0 = useAuth0<CustomUser>();
  const auth = useMemo(() => new Auth(auth0), [auth0]);

  return {
    // we spread auth0 helpers for convenience so all our components
    // that use `useAuth` also have access to `useAuth0` values
    ...auth0,
    // next we spread our custom auth instance which contains helpers and
    // also contains custom aut0 overrides e.g. for logout()
    ...auth,
  };
};
