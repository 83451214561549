import { SHIPMENT_LIST_VIEWS } from './shipments';
import { SHIPMENT_DETAILS_TABS } from '../../../AppMain/utils/constants/shipments';
// Using AppMain constant as it covers all cases

/**
 * Activity Logging values based on:
 * https://docs.google.com/spreadsheets/d/1JHQudiNWLkxL6AiYUOtqD5O6-Y6DnjGp3a7qth8QeEA/edit#gid=0
 */

const VIEW_SHIPMENT_LIST = {
  eventType: 'view_shipment_list',
  viewOptions: {
    [SHIPMENT_LIST_VIEWS.default]: 'standard',
    [SHIPMENT_LIST_VIEWS.delivery]: 'delivery_management',
  },
  triggers: {
    fromShipmentDetails: 'from_shipment_details',
    onChangeTradeDirection: 'on_change_trade_direction',
    onChangeSort: 'on_change_sort',
    onChangeShipmentStatus: 'on_change_shipment_status',
    onChangeNssFilter: 'on_change_nss_filter',
    onChangeAlertFilter: 'on_change_alert_filter',
    onChangeLabelFilter: 'on_change_label_filter',
    onChangeBookingStatus: 'on_change_booking_status',
    onChangePOL: 'on_change_pol',
    onChangePOD: 'on_change_pod',
    onChangeShipper: 'on_change_shipper',
    onChangeConsignee: 'on_change_consignee',
    onChangeAssignedTo: 'on_change_assigned_to',
    onChangeExternalAssigned: 'on_change_external_assigned',
    onChangeDeliveryPlace: 'on_change_delivery_place',
    onChangeCustomerTeam: 'on_change_customer_team',
    onChangePage: 'on_change_page',
    directAccess: 'direct_access',
    onChangeProducts: 'on_change_products',
  },
};

const SHIPMENT_DETAILS_LAYOUT_OPTIONS = {
  onePane: '1_pane',
  twoPaneList: '2_pane_list',
  twoPaneChat: '2_pane_chat',
  threePane: '3_pane',
};

const VIEW_SHIPMENT_DETAILS = {
  eventType: 'view_shipment_details',
  options: {
    viewLayout: {
      key: 'view_layout',
      values: {
        [SHIPMENT_DETAILS_LAYOUT_OPTIONS.onePane]: '1_pane',
        [SHIPMENT_DETAILS_LAYOUT_OPTIONS.twoPaneList]: '2_pane_list',
        [SHIPMENT_DETAILS_LAYOUT_OPTIONS.twoPaneChat]: '2_pane_chat',
        [SHIPMENT_DETAILS_LAYOUT_OPTIONS.threePane]: '3_pane',
      },
    },
    tab: {
      key: 'tab',
      values: {
        [SHIPMENT_DETAILS_TABS.milestones]: 'milestone',
        [SHIPMENT_DETAILS_TABS.tasks]: 'task',
        [SHIPMENT_DETAILS_TABS.files]: 'file',
        [SHIPMENT_DETAILS_TABS.invoices]: 'invoice',
        [SHIPMENT_DETAILS_TABS.detailedInfo]: 'detailed_info',
        [SHIPMENT_DETAILS_TABS.product]: 'product',
      },
    },
  },
  triggers: {
    fromShipmentList: 'from_shipment_list',
    fromChatList: 'from_chat_list',
    directAccess: 'direct_access',
    tabChange: 'tab_change',
    fromNotificationList: 'from_bell',
  },
};

const VIEW_SHIPMENT_DETAILS_FROM_CHAT_LIST_EVENT = {
  eventTypes: [VIEW_SHIPMENT_DETAILS.eventType],
  trigger: VIEW_SHIPMENT_DETAILS.triggers.fromChatList,
  options: {
    [VIEW_SHIPMENT_DETAILS.options.viewLayout.key]:
      VIEW_SHIPMENT_DETAILS.options.viewLayout.values[SHIPMENT_DETAILS_LAYOUT_OPTIONS.twoPaneChat],
    [VIEW_SHIPMENT_DETAILS.options.tab.key]:
      VIEW_SHIPMENT_DETAILS.options.tab.values[SHIPMENT_DETAILS_TABS.milestones],
  },
};

const VIEW_QUOTE_SIMULATION_DETAILS = {
  eventType: 'view_quote_simulation_details',
  options: {
    viewLayout: {
      key: 'view_layout',
      values: {
        normal: 'normal',
        detailsChat: 'details_chat',
      },
    },
  },
  triggers: {
    fromQuotePattern: 'from_quote_pattern',
    tabChange: 'tab_change',
  },
};

const VIEW_QUOTE_SIMULATION_PATTERN = {
  eventType: 'view_quote_simulation_pattern',
  options: {
    viewLayout: {
      key: 'view_layout',
      values: {
        normal: 'normal',
        chat: 'quote_pattern_chat',
      },
    },
  },
  triggers: {
    tabChange: 'tab_change',
    fromChatList: 'from_chat_list',
    fromQuoteDetails: 'from_quote_details',
    fromSavedQuote: 'from_saved_quote',
    directAccess: 'direct_access',
  },
};

const NOTICE_LIST_EVENT_TYPES = {
  openList: 'view_notification_list',
};

const NOTICE_LIST = {
  createListOpenEventParams: (unreadNoticeCount) => ({
    eventTypes: [NOTICE_LIST_EVENT_TYPES.openList],
    options: {
      number_of_unread: unreadNoticeCount,
    },
    // Hard coded, as there is only one way to open the notification list
    trigger: 'direct_access',
  }),
};

const VIEW_SAVED_QUOTE_LIST = {
  eventType: 'view_saved_quote_list',
  triggers: {
    tabChange: 'tab_change',
    directAccess: 'direct_access',
  },
};

const VIEW_CHAT_LIST = {
  eventType: 'view_chat_list',
  // By clicking the chat icon on the topbar is the only way to view the chat list
  trigger: 'direct_access',
};

const VIEW_LOGIN_EVENT_TYPE = 'view_login';

const UI_AREAS = {
  chatList: 'chat_list',
  invoiceList: 'invoice_list',
  quoteSimulation: 'quote_simulation',
  quoteDetails: 'quote_details',
  quotePattern: 'quote_pattern',
  shipmentList: 'shipment_list',
  shipmentDetails: 'shipment_details',
  shipmentDetailsInvoice: 'shipment_details_invoice',
};

const ACTION_CHAT_OPEN = 'action_chat_open';

const CHAT_OPEN = {
  eventType: ACTION_CHAT_OPEN,
  createEventParams(uiArea) {
    return {
      eventTypes: [ACTION_CHAT_OPEN],
      options: { uiArea },
    };
  },
};

const ACTION_CHAT_SENT = 'action_chat_sent';

const FILE_UPLOAD = {
  eventTypes: {
    upload: 'action_file_upload',
    reupload: 'action_file_reupload',
  },
  methods: {
    fileSelect: 'file_select',
    dragAndDrop: 'drag_and_drop',
  },
};

const ACTION_QUOTE_SAVE = 'action_quote_save';

export {
  VIEW_SHIPMENT_LIST,
  VIEW_SHIPMENT_DETAILS,
  VIEW_SHIPMENT_DETAILS_FROM_CHAT_LIST_EVENT,
  SHIPMENT_DETAILS_LAYOUT_OPTIONS,
  VIEW_QUOTE_SIMULATION_DETAILS,
  VIEW_QUOTE_SIMULATION_PATTERN,
  NOTICE_LIST_EVENT_TYPES,
  NOTICE_LIST,
  VIEW_SAVED_QUOTE_LIST,
  VIEW_CHAT_LIST,
  VIEW_LOGIN_EVENT_TYPE,
  UI_AREAS,
  CHAT_OPEN,
  ACTION_CHAT_SENT,
  FILE_UPLOAD,
  ACTION_QUOTE_SAVE,
};
