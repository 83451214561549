import UIEvent from './loggableUIEvent';
import * as types from './types';

/**
 * Create action to push event onto `uiEvents` stack
 * @param { {eventTypes: string[]; trigger: string; options?: any } } event
 * @returns Action
 */
const pushEvent = (event) => ({
  type: types.UIEVENTS_PUSH,
  payload: { event: new UIEvent(event) },
});

/**
 * Create action to remove single event in `uiEvents` stack
 * @param {string} id ID of event to remove from state
 * @returns Action
 */
const removeEvent = (id) => ({
  type: types.UIEVENTS_REMOVE,
  payload: { id },
});

export {
  pushEvent,
  removeEvent,
};
