const INVOICE_STATUS_FILTER_EDIT = 'app/invoices/editSelectedFilterOption';
const INVOICE_SEARCH_EDIT = 'app/shipments/editSearchInvoice';
const ANYCARGO_MONTH_EDIT = 'app/shipments/editMonthAnycargo';
const ANYCARGO_YEAR_EDIT = 'app/shipments/editYearAnycargo';

export {
  INVOICE_STATUS_FILTER_EDIT,
  INVOICE_SEARCH_EDIT,
  ANYCARGO_MONTH_EDIT,
  ANYCARGO_YEAR_EDIT,
};
